import React from "react"
import { useStaticQuery, Link } from "gatsby"
import { BookingButton, BookingButtonDisabled } from "./atoms/Button"
import { Box } from "./atoms/Box"
import { Order } from "./formPage/FormPage"
import Promotion from "../components/kampanj/Promotion"
import styles from "./jumbo.module.css"

const Jumbo = () => {
  const { allPrismicPricebox } = useStaticQuery(query)

  const data = allPrismicPricebox.edges[0].node.dataRaw

  return (
    <div className="jumbo">
      <div className="jumbo_left">
        <div className="flex flex-col  md:gap-2">
          <div className="flex gap-2">
            <h1 className="text-center">Välkommen till Amins Trafikskola!</h1>
            {/*            <p>
              Vi gör vårt bästa för att du ska få en trivsam och effektiv
              utbildningstid som gör dig till en säker förare. När du kör hos
              oss har du stor inverkan på hur du vill anpassa din utbildning!
            </p>*/}
            {/*            <div className="pt-2">
              <Link to={"/priser"}>
                <div css={StandardButton}>Bygg ihop ditt körpaket</div>
              </Link>
            </div>*/}
          </div>
          <div
            className={
              "md:border-l-1 md:border-white flex flex-col md:flex-row"
            }
          >
            <div className="flex-1 ">
              <Promotion
                priceNum={4995}
                originalPrice={6745 + 700 + 150}
                minimal
                title="Intensivkurs BIL Göteborg"
                heading="Kampanj bil intensivkurs"
                lineItems={[
                  {
                    title: "Kampanj körkortspaket BIL",
                    label: "10 körlektioner + online-tester",
                    price: 4995,
                    vat: 927.3,
                    id: 10097,
                  },
                ]}
              />
            </div>
            <div className="flex-1">
              <Promotion
                priceNum={18_990}
                originalPrice={20_460}
                minimal
                heading="MC - En veckas intensivkurs"
                lineItems={[
                  {
                    title: "MC - En veckas intensivkurs",
                    price: 18990,
                    vat: 3491.58,
                    id: 10098,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="jumbo_right">
        <h2>Kurstillfällen 2024</h2>
        <section className={styles.price_grid}>
          <div>
            <div css={Box}>
              <h3 style={{ margin: "8px" }}>Handledarkurs </h3>
            </div>
            <div className="info-headers bold">
              <div>Datum</div>
              <div>Tid</div>
              <div>Pris</div>
              <div>Platser</div>
              <div></div>
            </div>
            {data.handledarutbildning?.map(row => (
              <div
                className="info-headers"
                key={row.datum[0]?.datum + "_handl"}
              >
                <div>{row.datum[0]?.text}</div>
                <div>{row.tid[0]?.text}</div>
                <div>{row.pris[0]?.text}</div>
                <div>{row.lediga_platser}</div>
                {row.lediga_platser && row.lediga_platser > 0 ? (
                  <Link
                    to={"/formular"}
                    state={{
                      orderState: {
                        items: [
                          {
                            title: "Handledarutbildning",
                            price: 399,
                            vat: 79.8,
                            id: 8859,
                          },
                        ],
                      },
                      orderType: Order.NORMAL,
                      date: row.datum[0]?.text + ", " + row.tid[0]?.text,
                    }}
                  >
                    <div css={BookingButton}>Boka</div>
                  </Link>
                ) : (
                  <div css={BookingButtonDisabled}>Fullt</div>
                )}
              </div>
            ))}
          </div>
          <div>
            <div css={Box}>
              <h3 style={{ margin: "8px" }}>Mopedkurs </h3>
            </div>
            <div className="info-headers bold">
              <div>Datum</div>
              <div>Tid</div>
              <div>Pris</div>
              <div>Platser</div>
              <div> </div>
            </div>
            {data.mopedkurs?.map(row => (
              <div
                className="info-headers"
                key={row.datum[0]?.datum + "_moped"}
              >
                <div>{row.datum[0]?.text}</div>
                <div>{row.tid[0]?.text}</div>
                <div>{row.pris[0]?.text}</div>
                <div>{row.lediga_platser}</div>
                {row.lediga_platser && row.lediga_platser > 0 ? (
                  <Link
                    to={"/formular"}
                    state={{
                      orderState: {
                        items: [
                          {
                            title: "Mopedkurs AM",
                            price: 4990,
                            vat: 868.94,
                            id: 10474,
                          },
                        ],
                      },
                      orderType: Order.NORMAL,
                      date: row.datum[0]?.text + ", " + row.tid[0]?.text,
                    }}
                  >
                    <div css={BookingButton}>Boka</div>
                  </Link>
                ) : (
                  <div css={BookingButtonDisabled}>Fullt</div>
                )}
              </div>
            ))}
          </div>
          <div>
            <div css={Box}>
              <h3 style={{ margin: "8px", minWidth: "100px" }}>
                Riskettan Bil 🚘
              </h3>
            </div>
            <div className="info-headers bold">
              <div>Datum</div>
              <div>Tid</div>
              <div>Pris</div>
              <div>Platser</div>
              <div> </div>
            </div>
            {data.riskettan?.map(row => (
              <div
                className="info-headers"
                key={row.datum[0]?.datum + "_riskettan"}
              >
                <div>{row.datum[0]?.text}</div>
                <div>{row.tid[0]?.text}</div>
                <div>{row.pris[0]?.text}</div>
                <div>{row.lediga_platser}</div>
                {row.lediga_platser && row.lediga_platser > 0 ? (
                  <Link
                    to={"/formular"}
                    state={{
                      orderState: {
                        items: [
                          {
                            title: "Riskettan B",
                            price: 550,
                            vat: 110,
                            id: 8860,
                          },
                        ],
                      },
                      orderType: Order.NORMAL,
                      date: row.datum[0]?.text + ", " + row.tid[0]?.text,
                    }}
                  >
                    <div css={BookingButton}>Boka</div>
                  </Link>
                ) : (
                  <div css={BookingButtonDisabled}>Fullt</div>
                )}
              </div>
            ))}
          </div>
          <div>
            <div css={Box}>
              <h3 style={{ margin: "8px", minWidth: "100px" }}>
                Riskettan MC 🏍
              </h3>
            </div>
            <div className="info-headers bold">
              <div>Datum</div>
              <div>Tid</div>
              <div>Pris</div>
              <div>Platser</div>
              <div> </div>
            </div>
            {data.riskettan_mc?.map(row => (
              <div
                className="info-headers"
                key={row.datum[0]?.datum + "_riskettan"}
              >
                <div>{row.datum[0]?.text}</div>
                <div>{row.tid[0]?.text}</div>
                <div>{row.pris[0]?.text}</div>
                <div>{row.lediga_platser}</div>
                {row.lediga_platser && row.lediga_platser > 0 ? (
                  <Link
                    to={"/formular"}
                    state={{
                      orderState: {
                        items: [
                          {
                            title: "Riskettan MC",
                            price: 800,
                            vat: 160,
                            id: 10083,
                          },
                        ],
                      },
                      orderType: Order.NORMAL,
                      date: row.datum[0]?.text + ", " + row.tid[0]?.text,
                    }}
                  >
                    <div css={BookingButton}>Boka</div>
                  </Link>
                ) : (
                  <div css={BookingButtonDisabled}>Fullt</div>
                )}
              </div>
            ))}
          </div>
        </section>

        <hr />
      </div>
    </div>
  )
}

const query = graphql`
  query {
    allPrismicPricebox {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`

export default Jumbo
